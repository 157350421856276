import { parse } from "qs";

import { Query } from "custom-types/Query";

export default (asPath: string) => {
  const [url, hash] = asPath.split("#");
  const [path, query] = url.split("?");

  return { hash, path, query: parse(query) as Query };
};
