import React, { Children, useState } from "react";
import classnames from "classnames";
import Link from "next/link";

import ArrowIcon from "components/Icons/arrows/ArrowIcon";
import ChevronIcon from "components/Icons/ChevronIcon";

type Props = {
  /**
   * Number of cards to show per slide on desktop
   */
  cardsPerSlide?: number;

  /**
   * Array of cards to display in the carousel;
   * No classes or attributed are needed
   */
  children: JSX.Element | JSX.Element[];

  /**
   * Optional className to apply to the wrapper `div`
   */
  className?: string;
  /**
   * Optional className to appply to the heading
   */
  headingClassName?: string;

  id?: string;

  /**
   * Optional width to apply to cards on mobile and
   */
  mobileCardWidth?: number;

  /**
   * What to show in the case of no children are provided;
   * Can be text or a full element;
   * Will display centered in the carousel
   */
  noResultsDisplay?: string | JSX.Element;

  /**
   * Optional class to apply to the "See more" card, such as `bg-white shadow-low`
   */
  seeMoreCardClass?: string;

  /**
   * Path used for all see more links/cards;
   * Will only show if there are multiple pages
   */
  seeMoreHref?: string;

  /**
   * Click handler used for all see more links/cards;
   * Can be used for event tracking callbacks;
   * Will only show if there are multiple pages
   */
  seeMoreOnClick?: (e: React.KeyboardEvent | React.MouseEvent) => void;

  /**
   * Text override for the see more buttons;
   * `seeMoreHref` or `seeMoreOnClick` must be passed to show these;
   * * Will only show if there are multiple pages
   */
  seeMoreText?: string;

  /**
   * Color override for the see more link that sometimes shows;
   * `seeMoreHref` or `seeMoreOnClick` must be passed to show these;
   * "green" and "default" are allowed by default since our tailwind config supports them.
   */
  seeMoreColor?: "green" | "default";

  /**
   * Whether to show the "See more" card in the carousel
   */
  showSeeMoreCard?: boolean;

  /**
   * Displays under the header, can be text or a full element
   */
  subtitle?: string | JSX.Element;

  /**
   * Header for the carousel, can be text or a styled element
   */
  title?: string | JSX.Element;

  preventCollapse?: boolean;
};

const Carousel = ({
  cardsPerSlide = 4,
  children,
  className,
  headingClassName,
  mobileCardWidth = 250,
  noResultsDisplay,
  seeMoreCardClass,
  seeMoreHref,
  seeMoreOnClick,
  seeMoreColor = "green",
  seeMoreText = "show all",
  showSeeMoreCard,
  subtitle,
  title,
  preventCollapse = false,
  ...others
}: Props) => {
  const [carouselPage, setCarouselPage] = useState(0);

  const cardCount =
    showSeeMoreCard && Children.count(children) > cardsPerSlide
      ? Children.count(children) + 1
      : Children.count(children);

  if (!cardCount && !noResultsDisplay) {
    return null;
  }

  const totalPages = Math.ceil(cardCount / cardsPerSlide);

  const handleNextClick = () => {
    setCarouselPage(carouselPage + 1);
  };

  const handlePrevClick = () => {
    setCarouselPage(carouselPage - 1);
  };

  /* List */
  // Translate 100% plus the margin for each page
  const listStyle = {
    transform: `translateX(calc(-${100 * carouselPage}% - ${
      12 * carouselPage
    }px))`,
  };

  // Desktop: calculate percentage width of the list for each card, minus the margins in between
  const cardWidthPercentage = (1 / cardsPerSlide) * 100;
  const cardMarginWidthShare = ((cardsPerSlide - 1) * 12) / cardsPerSlide;

  const titleElement =
    typeof title === "string" && !headingClassName ? <h2>{title}</h2> : title;

  const subtitleElement =
    typeof subtitle === "string" ? <p>{subtitle}</p> : subtitle;

  const showSeeMoreText = seeMoreHref && seeMoreText && totalPages > 1;

  const hasShowMoreCard = showSeeMoreCard && showSeeMoreText;

  const largeScreenCardWidthCSSString = preventCollapse
    ? `${mobileCardWidth}px`
    : `calc(${cardWidthPercentage}% - ${cardMarginWidthShare}px)`;

  return (
    <>
      <div data-testid="carousel" className={classnames(className)} {...others}>
        <div className="flex items-end justify-between">
          <div className={headingClassName}>
            {title && titleElement}
            {subtitle && subtitleElement}
          </div>

          <div
            className={classnames("hidden text-green items-center", {
              "lg:flex": !preventCollapse,
            })}
          >
            {showSeeMoreText && (
              <Link
                className={`text-${seeMoreColor} font-bold underline`}
                href={seeMoreHref}
                onClick={seeMoreOnClick}
                data-testid="carousel-see-more-link"
              >
                {seeMoreText}
              </Link>
            )}
            {totalPages > 1 && (
              <span className="ml-xxl">
                <button
                  className={classnames(
                    "rounded-full bg-white shadow-low mr-lg",
                    {
                      "text-deep-green-40": carouselPage === 0,
                    },
                  )}
                  disabled={carouselPage === 0}
                  aria-label="See previous"
                  onClick={handlePrevClick}
                >
                  <ChevronIcon direction="left" />
                </button>
                <button
                  className={classnames("rounded-full bg-white shadow-low", {
                    "text-deep-green-40": carouselPage === totalPages - 1,
                  })}
                  disabled={carouselPage === totalPages - 1}
                  aria-label="See next"
                  onClick={handleNextClick}
                >
                  <ChevronIcon direction="right" />
                </button>
              </span>
            )}
          </div>
        </div>

        <div
          className={classnames(
            "overflow-auto -mx-sm p-sm pb-md snap-x snap-mandatory scroll-px-sm",
            { "lg:overflow-hidden": !preventCollapse },
          )}
          style={{ width: "calc(100% + 16px)" }}
        >
          {Children.count(children) ? (
            <ul
              className={classnames(
                "inline-flex -mr-sm lg:mr-none transition-transform carousel-list",
                { "lg:flex": !preventCollapse },
              )}
              style={listStyle}
            >
              {Children.map(children, (child, i) => (
                <li
                  className={classnames(`carousel__card flex-shrink-0 mr-md`, {
                    "snap-end":
                      !hasShowMoreCard && i === Children.count(children) - 1,
                    "snap-start":
                      hasShowMoreCard || i < Children.count(children) - 1,
                  })}
                >
                  {child}
                </li>
              ))}
              {hasShowMoreCard && (
                <li
                  className={classnames(
                    `carousel__card flex-shrink-0 snap-end`,
                    seeMoreCardClass,
                  )}
                >
                  <Link
                    className="w-full h-full flex flex-col items-center justify-center bg-white"
                    href={seeMoreHref}
                    onClick={seeMoreOnClick}
                  >
                    <div className="bg-light-grey rounded-full p-xl mb-sm">
                      <ArrowIcon width="20" height="20" direction="right" />
                    </div>

                    <span className="font-bold">{seeMoreText}</span>
                  </Link>
                </li>
              )}
            </ul>
          ) : (
            <div
              className="flex items-center justify-center w-full"
              style={{ minHeight: "100px" }}
            >
              {noResultsDisplay}
            </div>
          )}
        </div>

        {showSeeMoreText && (
          <Link
            className={classnames(
              `block text-${seeMoreColor} font-bold mt-xs cursor-pointer underline`,
              { "lg:hidden": !preventCollapse },
            )}
            href={seeMoreHref}
            onClick={seeMoreOnClick}
          >
            {seeMoreText}
          </Link>
        )}
      </div>
      <style jsx>{`
        .carousel__card {
          width: ${mobileCardWidth}px;
        }
        @media only screen and (min-width: 1025px) {
          .carousel__card {
            width: ${largeScreenCardWidthCSSString};
          }
        }
      `}</style>
    </>
  );
};

export default Carousel;
