import {
  clear as clearStore,
  createStore,
  get,
  set,
  UseStore,
} from "idb-keyval";

import logError from "./logError";

export const userHistoryEntryTypes = [
  "dispensary",
  "strain",
  "news-article",
  "product",
  "brand",
  "menu-item",
] as const;

export type UserHistoryEntryType = (typeof userHistoryEntryTypes)[number];
type AttributesType = {
  [characterName: string]: number | string;
};

export type UserHistoryEntryInput = {
  slug?: string;
  type: UserHistoryEntryType;
  resourceId: number;
  attributes?: AttributesType;
  userId?: number;
};

export type UserHistoryEntry = UserHistoryEntryInput & {
  sessionId?: string;
  date: string;
  url: string;
};

const createHistoryStore = () =>
  typeof window !== "undefined"
    ? createStore("user-history", "keyvaluepairs")
    : undefined;

const DEBOUNCE_WINDOW = 60 * 1000;
const HISTORY_LIMIT = 200;
const HISTORY_LOCATION = "history";

function _dedupeResults(entries: UserHistoryEntry[]) {
  const dedupedSet = new Set(entries.map((x) => `${x.resourceId}-${x.type}`));
  const results: UserHistoryEntry[] = [];

  entries.forEach((x) => {
    const shouldAdd = dedupedSet.has(`${x.resourceId}-${x.type}`);
    if (shouldAdd) {
      results.push(x);
      dedupedSet.delete(`${x.resourceId}-${x.type}`);
    }
  });

  return results;
}

export function _getSessionId(): string {
  const defaultSessionId = "-1";
  try {
    const sessionCookieRegex =
      /(?:[\s;]|^)leafly.browser.session[^=]*=([^;]*)/g;
    const cookie = document.cookie.match(sessionCookieRegex);

    return cookie ? cookie[0].substring(23) : defaultSessionId;
  } catch (err) {
    return defaultSessionId;
  }
}

async function _getEntries(store?: UseStore) {
  const entries = store
    ? (await get<UserHistoryEntry[]>(HISTORY_LOCATION, store)) ?? []
    : [];

  return entries;
}

/**
 *
 * Saves an entry to a user's history.
 * @example Tracking a dispensary visit
 * ```javascript
 * const entry = {
 *    slug: 'the-reef---seattle',
 *    type: 'dispensary',
 *    resourceId: 123,
 *    userId: 1
 * };
 * await track(entry);
 * @example Tracking a menu item pdp visit
 * ```
 * ```javascript
 * const entry = {
 *    slug: 'the-reef---seattle',
 *    type: 'dispensary',
 *    resourceId: 514571253,
 *    attributes: {
 *      variantId: 167784045
 * },
 *    userId: 1
 * };
 * await track(entry);
 * ```
 */
export async function trackUserHistory(entryInput: UserHistoryEntryInput) {
  const store = createHistoryStore();

  const sessionId = _getSessionId();
  const url = window !== undefined ? window.location.toString() : "";
  const previousEntries = await _getEntries(store);
  const entry = {
    date: new Date().toISOString(),
    sessionId,
    url,
    ...entryInput,
  };

  const mostRecentEntry = previousEntries[0];

  const sameTypeAndResource =
    mostRecentEntry?.type === entry.type &&
    mostRecentEntry?.resourceId === entry.resourceId;

  const mostRecentMatchesAndInDebounceWindow =
    sameTypeAndResource &&
    +new Date(entry.date) - +new Date(mostRecentEntry?.date) <
      DEBOUNCE_WINDOW &&
    JSON.stringify(mostRecentEntry?.attributes) ===
      JSON.stringify(entry.attributes);

  if (mostRecentMatchesAndInDebounceWindow) {
    return;
  }

  try {
    const exceedsHistoryLimit = previousEntries.length >= HISTORY_LIMIT;

    if (exceedsHistoryLimit) {
      previousEntries.pop();
    }
    previousEntries.unshift(entry);

    if (store) {
      await set(HISTORY_LOCATION, previousEntries, store);
    }

    return;
  } catch (err) {
    logError("error attempting to store history", {
      functionName: "getItems",
      service: "user-history",
    });
  }
}

export async function clearUserHistory() {
  const store = createHistoryStore();

  try {
    if (store) {
      return await clearStore(store);
    } else {
      throw new Error("store not found");
    }
  } catch (err) {
    logError("error attempting to clear history", {
      functionName: "getItems",
      service: "user-history",
    });
  }
}

/**
 * @param {EntryType} type
 * @param {AttributesType} [attributes]
 * @param {number} [limit=100]
 * @returns
 * @example Fetching all menu-item history
 * ```javascript
 * const entries = await getItems('menu-item');
 * ```
 * @example Overriding default limit
 * ```javascript
 * const entries = await getItems('dispensary', undefined, limit=500);
 * ```
 * @example Filtering with attributes for a specific dispensary
 * ```javascript
 * const entry = {
 *    slug: 'the-reef---seattle',
 *    type: 'dispensary',
 *    resourceId: 514571253,
 *    attributes: {
 *      category: "Flower",
 *      variantId: 167784045
 * },
 *    userId: 1
 * };
 * await track(entry);
 * const entries = await getItems('dispensary', {category: "Flower"});
 * ```
 * @example Filtering for a specific dispensary
 * ```javascript
 *
 * const entry = {
 *    slug: 'the-reef---seattle',
 *    type: 'dispensary',
 *    resourceId: 514571253,
 *    attributes: {
 *      category: "Flower",
 *      variantId: 167784045
 * },
 *    userId: 1
 * };
 * await track(entry);
 * const entries = await getItems('menu-item')
 * const itemsForTheReef = entries.filter(entry => entry.slug === 'the-reef---seattle')
 * ```
 */
export const getUserHistoryItems = async (
  type: UserHistoryEntryType,
  attributes?: AttributesType,
  limit = 100,
) => {
  try {
    const store = createHistoryStore();

    const entries = await _getEntries(store);

    const results = entries.filter((entry) => {
      if (!attributes) {
        return entry.type === type;
      }

      return Object.keys(attributes).reduce(
        (matchesAttributes, attribute) =>
          !!(
            matchesAttributes &&
            entry.attributes?.[attribute] === attributes[attribute]
          ),
        true,
      );
    });

    return _dedupeResults(entries ? results : []).slice(0, limit);
  } catch (err) {
    logError("error attempting to get history", {
      functionName: "getItems",
      service: "user-history",
    });

    return [];
  }
};
